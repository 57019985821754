.site-layout-content {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  min-height: 280px;
  height: 100%;
  background: #fff;
  padding-top: 30px;
}

.ant-layout-header.logo {
  background-color: white;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  padding: 0px;
}

.ant-menu-dark.ant-menu-horizontal {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.layout {
  height: 100%;
}

.ant-layout-content {
  height: 100%;
}

.menu {
  text-transform: uppercase;
  font-weight: bold;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: white !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: white !important;
}

.social {
  width: 40px;
  height: 40px;
  padding: 5px;
}

.section-header {
  color: #ff0000;
  text-transform: uppercase;
}

.ant-card-head {
  border-bottom: 1px solid black !important;
}

@primary-color: #FF0000;@layout-header-background: #000000;@layout-body-background: #FFFFFF;